import {
  StyleFunctionProps,
  createMultiStyleConfigHelpers,
  defineStyleConfig,
  extendTheme,
  withDefaultVariant,
} from "@chakra-ui/react"
import { chain } from "lodash"

import theme from "./theme.config"

const Input = createMultiStyleConfigHelpers([
  "field",
  "addon",
  "element",
]).defineMultiStyleConfig({
  variants: {
    outline: (props: StyleFunctionProps) => ({
      field: {
        bg: props.colorMode === "dark" ? theme["gray.900"] : theme.white,
        borderColor:
          props.colorMode === "dark" ? theme["gray.700"] : theme["gray.200"],
        color: "primary",
      },
    }),
  },
})

const Button = defineStyleConfig({
  sizes: {
    xs: {
      lineHeight: 0.75,
    },
  },
  variants: {
    nav: (props: StyleFunctionProps) => ({
      bg: props.colorMode === "dark" ? "black" : "white",
      borderWidth: "1px",
      borderColor: theme["gray.200"],
    }),
  },
})

const Select = createMultiStyleConfigHelpers([
  "field",
  "icon",
]).defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    nav: (props: StyleFunctionProps) => ({
      field: {
        bg: props.colorMode === "dark" ? "black" : "white",
        borderWidth: "1px",
        borderColor: theme["gray.200"],
      },
    }),
    outline: (props: StyleFunctionProps) => ({
      field: {
        borderRadius: "md",
        bg: props.colorMode === "dark" ? theme["gray.600"] : theme.white,
        borderColor:
          props.colorMode === "dark" ? theme["gray.700"] : theme["gray.200"],
      },
    }),
  },
})

const FormError = {
  baseStyle: {
    text: {
      flexDirection: "column",
    },
  },
}

const Table = createMultiStyleConfigHelpers([
  "table",
  "td",
  "th",
  "caption",
]).defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    details: (props: StyleFunctionProps) => ({
      table: {},
      th: {
        padding: "2px",
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "28px",
        textTransform: "none",
        color: "secondary",
        verticalAlign: "top",
        letterSpacing: "none",
      },
      td: {
        padding: "2px",
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "28px",
        color: "primary",
        verticalAlign: "top",
        letterSpacing: "none",
      },
    }),
  },
})

export const chakraTheme = extendTheme(
  withDefaultVariant({
    variant: "outline",
    components: ["Button", "Input", "Select"],
  }),
  {
    fonts: {
      heading: "'Inter', sans-serif",
      body: "'Inter', sans-serif",
    },
    semanticTokens: {
      colors: {
        primary: {
          default: theme.primary,
          _dark: theme.darkPrimary,
        },
        secondary: {
          default: theme.secondary,
          _dark: theme.darkSecondary,
        },
        tertiary: {
          default: theme.tertiary,
          _dark: theme.darkTertiary,
        },

        // Include all "color.value" colours (e.g. `{gray: {100: "#FFFFFF"}}`)
        ...chain(theme)
          .entries()
          .filter((v) => v.includes("."))
          .mapKeys(([key]) => key)
          .groupBy(([key]) => key.split(".")[0])
          .mapValues(([value]) =>
            chain(value)
              .mapKeys(([key]) => key.split(".")[1])
              .mapValues(([_, color]) => color)
          )
          .value(),
      },
    },
    components: {
      Button,
      Select,
      Input,
      Table,
      FormError: FormError,
    },
  }
)

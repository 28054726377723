import { ChakraProvider } from "@chakra-ui/react"
import { createInertiaApp } from "@inertiajs/react"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import _ from "lodash"
import React from "react"
import { createRoot } from "react-dom/client"

import "../css/app.css"
import "./bootstrap"
import { chakraTheme } from "./chakra.config"
import { RouteContext } from "@/Hooks/useRoute"

const appName = "Insite"

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  progress: {
    color: "#4B5563",
  },
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.tsx`,
      import.meta.glob("./Pages/**/*.tsx")
    ),
  setup({ el, App, props }) {
    const root = createRoot(el)
    return root.render(
      <RouteContext.Provider value={(window as any).route}>
        <ChakraProvider theme={chakraTheme}>
          <App {...props} />
        </ChakraProvider>
      </RouteContext.Provider>
    )
  },
})
